import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { text: "english", value: "en" },
  { text: "español", value: "es" },
  { text: "عربي", value: "ar" },
];

const LanguagePicker = () => {
  const [show, setShow] = useState(false);
  const { i18n } = useTranslation();

  const changeLanguage = async (value) => {
    let selectedLanguage = languages.find((l) => l.value === value);
    setShow(!show);

    try {
      await i18n.changeLanguage(selectedLanguage.value);
    } catch (error) {
      console.log("error changing language: ", error);
    }
  };

  const list = languages.map((l, i) => (
    <li key={i} onClick={() => changeLanguage(l.value)}>
      {l.text}
    </li>
  ));

  const languageText = (v) => {
    return languages.find((e) => e.value === v)?.text;
  };

  return (
    <ul>
      {show ? (
        list
      ) : (
        <li onClick={() => setShow(!show)}>{languageText(i18n.language)}</li>
      )}
    </ul>
  );
};

export default LanguagePicker;
