import styled from "styled-components";
import { useUserContext } from "../context/userContext";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import Signup from "./Signup";
import Signin from "./Signin";
import Dashboard from "./Dashboard";
import { NavLink } from "react-router-dom";
import LanguagePicker from "./LanguagePicker";

const logo = require("../Assets/logo.png");

const Main = styled.nav`
  align-items: center;
  background: transparent;
  justify-content: space-around;
  display: flex;
  margin: 0;
  padding: 3px;
  padding-top: 20px;
  position: fixed;
  transform: ${(props) =>
    props.visible ? "translateY(0px)" : "translateY(-90px)"};
  transition: all 800ms ease-in-out;
  width: 100vw;
  z-index: 10;

  main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    width: 90%;
  }

  .brand {
    display: flex;
    align-items: center;
    font-family: "Snell Roundhand", sans-serif;
    font-size: 1.5rem;
    gap: 10px;
    color: white;
    text-decoration: none;

    img {
      height: 1.5rem;
      width: auto;
    }
  }

  small {
    color: white;
    cursor: pointer;
    font-size: 1rem;
  }

  ul {
    display: flex;
    gap: 10px;
    list-style: none;
    margin: 20px;
    padding: 0;
    width: fit-content;

    li {
      border: 1px solid white;
      color: white;
      cursor: pointer;
      padding: 10px 30px;
      transition: all 0.3s ease;

      &:hover {
        border-color: transparent;
      }
    }
  }
`;

const Nav = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [visible, setVisible] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const [showSignup, setShowSignup] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);

  const scrolly = useRef(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });

    if (scrollPos > scrolly.current && scrolly.current >= 0) setVisible(false);
    else if (scrollPos <= scrolly.current && scrollPos < 300) setVisible(true);

    scrolly.current = scrollPos;

    return () => {
      // window.removeEventListener("scroll");
    };
  }, [scrollPos]);

  const hide = true;

  return (
    <Main visible={visible}>
      <main>
        <NavLink to="/" className="brand">
          <img src={logo} alt="domino apunte logo" />
          Domino Apunte
        </NavLink>

        <LanguagePicker />
        {!hide && (
          <div>
            {!user ? (
              <ul>
                <li onClick={() => setShowSignin(true)}>{t("nav.signin")}</li>
                <li onClick={() => setShowSignup(true)}>{t("nav.signup")}</li>
              </ul>
            ) : (
              <ul>
                <li onClick={() => setShowDashboard(true)}>
                  {user.displayName}
                </li>
              </ul>
            )}
          </div>
        )}
      </main>
      <Signin show={showSignin} onClose={() => setShowSignin(false)} />
      <Signup show={showSignup} onClose={() => setShowSignup(false)} />
      <Dashboard show={showDashboard} onClose={() => setShowDashboard(false)} />
    </Main>
  );
};

export default Nav;
